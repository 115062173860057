@import "bootstrap/scss/bootstrap";
@import "bootstrap-icons/font/bootstrap-icons.css";
@import "rsuite/dist/rsuite.min.css";
@import "./variables";
@import "./helpers";
@import "./rsuite_prefix_class";
@import "./buttons";

@font-face {
    font-family: Norms;
    src: url("../assets/fonts/tt_norms_pro_regular.otf");
}

@font-face {
    font-family: Norms;
    src: url("../assets/fonts/tt_normas_pro_bold.otf");
    font-weight: bold;
}

@font-face {
    font-family: Norms;
    src: url("../assets/fonts/tt_norms_pro_medium.otf");
    font-weight: 300;
}

html,
body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Norms", sans-serif;
    width: 100%;
    height: 100vh;
    overflow: auto;
    overflow-x: hidden;
    font-size: 16px;
    background-color: map-get($map: $bg-colors, $key: "bg");
    color: map-get($map: $colors, $key: "grey");
}

#root {
    height: 100%;
}

button {
    color: inherit !important;
}

hr {
    width: 100%;
    max-width: 100%;
}

.item-canceled {
    text-decoration: line-through;
}
