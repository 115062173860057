// Select Field

.rs-copec.rs-copec-select.rs-copec-default.rs-copec-toggle-wrapper.rs-copec-cleanable
    > .rs-picker-toggle.rs-btn.rs-btn-default {
    padding: 15px 9px !important;
    border: 2px solid map-get($map: $colors, $key: "border-color") !important;
    width: 100%;
    color: #000000ba !important;
    font-weight: bold;
    background-color: #fff;

    svg,
    span {
        top: 17px;
    }
}

.rs-copec-none {
    padding: 5px 15px;
}

.rs-copec.rs-copec-date.rs-copec-default.rs-copec-toggle-wrapper.rs-copec-cleanable {
    .rs-picker-toggle.rs-btn.rs-btn-default {
        padding: 15px 9px !important;
        border: 2px solid map-get($map: $colors, $key: "border-color") !important;
        width: 100%;
        color: #000000ba !important;
        font-weight: bold;
        background-color: #fff;

        .rs-picker-toggle-clean.rs-btn-close {
            top: 16px;
            right: 40px;
        }
        svg {
            top: 15px;
        }
    }
}

.rs-btn.rs-btn-primary.rs-btn-sm {
    color: #fff !important;
}
