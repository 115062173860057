.modal-component {
    width: 100%;
    height: 100vh;
    position: absolute;
    background-color: #00000030;
    top: 0;
    left: 0;
    padding: 15px;
    z-index: 9000;

    &__container {
        background-color: #fff;
        border-radius: 5px;
    }
}
