.render-btn-component {
    color: white;
    position: fixed;
    bottom: 40px;
    right: 20px;
    z-index: 1000;
    transform: translateX(400%);
    transition: transform 0.5s ease-in-out;

    button {
        background-color: #002af2;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 25px;
        border: 1px solid #ffffff3c;

        &:disabled {
            background-color: #4561ec;
        }

        &:active {
            width: 47px;
            height: 47px;
            font-size: 23px;
        }
    }
}

.render-btn-component.show{
    transform: translateX(0);
}