// Background Colors

.bg-default {
    background-color: map-get($map: $bg-colors, $key: "default");
}

.bg-white {
    background-color: map-get($map: $bg-colors, $key: "white");
}
.bg-grey {
    background-color: map-get($map: $bg-colors, $key: "grey");
}

.bg-blue {
    background-color: map-get($map: $colors, $key: "blue");
}

// Colors

.color-grey {
    color: map-get($map: $colors, $key: "grey");
}

.color-black {
    color: #000;
}

.color-blue {
    color: map-get($map: $colors, $key: "blue");
}

.color-white {
    color: map-get($map: $colors, $key: "white");
}

// Borders

.border-default {
    border: 1px solid map-get($map: $colors, $key: "border-color");
}

.border-bottom-default {
    border-bottom: 1px solid map-get($map: $colors, $key: "border-color");
}

// Font Size

.size-05 {
    font-size: 0.5em;
}
.size-06 {
    font-size: 0.6em;
}
.size-07 {
    font-size: 0.7em;
}
.size-08 {
    font-size: 0.8em;
}
.size-09 {
    font-size: 0.9em;
}
.size-11 {
    font-size: 1.1em;
}
.size-12 {
    font-size: 1.2em;
}
.size-13 {
    font-size: 1.3em;
}
.size-14 {
    font-size: 1.4em;
}
.size-15 {
    font-size: 1.5em;
}

// Rounded
.rounded-1 {
    border-radius: 1px !important;
}
.rounded-2 {
    border-radius: 2px !important;
}
.rounded-3 {
    border-radius: 3px !important;
}
.rounded-4 {
    border-radius: 4px !important;
}
.rounded-5 {
    border-radius: 5px !important;
}
.rounded-10 {
    border-radius: 10px !important;
}
.rounded-15 {
    border-radius: 15px !important;
}
.rounded-20 {
    border-radius: 20px !important;
}
.rounded-25 {
    border-radius: 25px !important;
}
.rounded-30 {
    border-radius: 30px !important;
}
.rounded-35 {
    border-radius: 35px !important;
}
.rounded-40 {
    border-radius: 40px !important;
}

// Shadows

.shadow {
    box-shadow: 1px 1px 3px rgb(0 0 0 / 15%) !important;
}

.shadow-bottom {
    box-shadow: 2px 2px 6px rgb(0 0 0 / 15%);
}

.shadow-left {
    box-shadow: -5px 3px 3px rgb(0 0 0 / 15%) !important;
}

// class
.bold {
    font-weight: bold;
}

.bold-300 {
    font-weight: 300;
}

.placeholder-default {
    &::placeholder {
        color: map-get($map: $colors, $key: "grey");
        font-weight: 300;
        font-size: 0.7rem;
    }
}

.capitalize {
    text-transform: capitalize;
}