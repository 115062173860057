.component-quantity {
  &__actions {
    width: 50px;
    height: 28px;
    background-color: #f8faff;
    color: #9aa0b1;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;

    &:first-child {
      border-radius: 8px 0 0 8px;
      border-left: 2px solid #e7ebf7;
      border-top: 2px solid #e7ebf7;
      border-bottom: 2px solid #e7ebf7;
    }

    &:last-child {
      border-radius: 0 8px 8px 0;
      border-right: 2px solid #e7ebf7;
      border-top: 2px solid #e7ebf7;
      border-bottom: 2px solid #e7ebf7;
    }
  }

  &__input {
    width: 100%;
    height: 28px;
    padding: 0;
    text-align: center;
    background-color: #fff;
    color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 2px solid #e7ebf7;
    border-bottom: 2px solid #e7ebf7;
    border-left: 2px solid #e7ebf7;
    border-right: 2px solid #e7ebf7;
    font-size: 14px;
  }
}
