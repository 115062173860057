.component-side-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 9999;
    transition: 0.2s ease;
    padding: 0 !important;
    margin: 0;

    &__container {
        background-color: #f7f9ff;
        margin: 0 !important;
        padding: 0 !important;
        left: 0;
        top: 0;
        width: 90%;
        min-height: 100vh;
        position: relative;

        a:hover {
            text-decoration: none;
            color: inherit;
        }
    }
}
